import {
  Category,
  CategoryReference,
  LocalizedString
} from "@commercetools/platform-sdk"
import { filterTruthy } from "../../utils/Filter"

export interface DomainCategory {
  readonly id: string
  readonly name: LocalizedString
  readonly slug: LocalizedString
  readonly ancestors: DomainCategory[]
  readonly parent: CategoryReference | null
  readonly orderHint: string
  readonly key: string
  readonly seoDescription: LocalizedString
}

export class DomainCategories {
  public static fromCategory(category: Category): DomainCategory {
    return {
      id: category.id,
      name: category.name,
      slug: category.slug,
      orderHint: category.orderHint,
      parent: category.parent || null,
      key: category.key || "",
      ancestors: category.ancestors
        .map(it => it.obj)
        .filter(filterTruthy)
        .map(DomainCategories.fromCategory),
      seoDescription: category.metaDescription || {}
    }
  }

  public static fromCategoryReference(
    categoryRef: CategoryReference | undefined
  ): DomainCategory | null {
    if (categoryRef && categoryRef.obj && categoryRef.obj.id) {
      return DomainCategories.fromCategory(categoryRef.obj)
    } else {
      return null
    }
  }
}
