import { Cart } from "@commercetools/platform-sdk"
import { useTranslation } from "react-i18next"
import { useAccessToken } from "../auth/AuthHooks"
import { useCommerceToolsClient } from "../commercetools/CommerceToolsHooks"
import { orderErrorUrl, createProcessingLink } from "../components/Links"
import { useTypedErrorApi } from "../error/ErrorHooks"
import { ErrorType } from "../error/ErrorType"
import { useStoreContext } from "../i18n/StoreHooks"
import { useRouter } from "../routing/useRouter"
import { useRootSelector, useAppDispatch, AppDispatch } from "../State"
import { createLogger } from "../utils/createLogger"
import { CartService } from "./CartService"
import {
  CartNotificationProduct,
  CartNotificationState,
  CartNotificationStatI
} from "./CartState"
import { countryToStore } from "./I18NStores"

const logger = createLogger("CartServiceHook")

export const useCartState = (): Cart | undefined =>
  useRootSelector(it => it.cartState.cart)

export const useCartId = (): string | undefined =>
  useRootSelector(it => it.cartState.cart?.id)

export const useCartNotificationState: () => CartNotificationStatI = () => {
  return useRootSelector(it => it.cartNotificationState)
}

export const useCartService = (dispatchFn?: AppDispatch) => {
  const dispatch = useAppDispatch()
  const { country } = useStoreContext()
  const client = useCommerceToolsClient()
  return new CartService(
    client,
    countryToStore(country),
    dispatchFn || dispatch
  )
}

export const useCartNotification = () => {
  const dispatch = useAppDispatch()
  return {
    notify: (product: CartNotificationProduct) => {
      dispatch(CartNotificationState.actions.notify(product))
    },
    clear: () => {
      dispatch(CartNotificationState.actions.clear())
    }
  }
}

interface CartToOrderResponse {
  orderNumber?: string
  cartId: string
  error?: {
    key: string
    message: string
  }
}

export const useCompleteCartCheckoutService = () => {
  const cartService = useCartService()
  const { accessToken: tokenInfo } = useAccessToken()
  const orderError = useTypedErrorApi(ErrorType.OrderError)
  const { t } = useTranslation("checkout")
  const router = useRouter()

  const validateFraud = (cartId: string): Promise<Cart> => {
    return cartService
      .fullCart(cartId)
      .then(it =>
        fetch(`/api/cart/${cartId}/verify`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenInfo?.access_token}`
          },
          credentials: "same-origin"
        })
      )
      .then((response: Response) => {
        switch (response.status) {
          case 204:
            return Promise.resolve(true)
          case 205:
            return router.push(createProcessingLink(cartId))
        }

        return response.json().then(it => {
          const { error, cartId } = it as CartToOrderResponse
          if (error) {
            logger.error(
              `Error verifying cart. Error details: ${JSON.stringify(
                error
              )}. Response status: ${response.status}`,
              error
            )
            orderError.setError({
              title: t(error?.key || "unableToVerifyCartFraudScoreTitle"),
              message: t(
                error?.message || "unableToVerifyCartFraudScoreMessage",
                { reference: cartId }
              )
            })
            return router.push(orderErrorUrl().href)
          }
          return Promise.resolve(true)
        })
      })
      .then(it => cartService.refreshCart() as Promise<Cart>)
  }

  return {
    validateFraud
  }
}
