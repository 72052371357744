import {
  ApiRequest,
  ByProjectKeyRequestBuilder,
  ClientResponse,
  ProductProjection,
  ProductProjectionPagedQueryResponse,
  ProductVariant,
  CategoryReference,
  Channel,
  QueryParam,
  Store as CommerceToolsStore
} from "@commercetools/platform-sdk"
import { Language } from "@sixty-six-north/i18n"
import { GlobalProps } from "next/GlobalProps"
import { memoizedFn, memoTimeout } from "../Cache"
import { Store } from "../cart/Stores"
import {
  ProductFacetFactory,
  ProductFacets
} from "../category/ProductFacetFactory"
import { ServerSideCommerceToolsClient } from "../commercetools/CommerceToolsClient"
import { getNextOffsets } from "../commercetools/Pagination"
import { today } from "../utils/DateUtils"
import { filterTruthy } from "../utils/Filter"
import logger from "../utils/logger"
import { Paginator } from "../utils/PaginatedRequests"
import {
  DetailedProductInformation,
  CoreProductInformation
} from "./models/DetailedProductInformation"
import { DomainCategory, DomainCategories } from "./models/DomainCategory"
import {
  AttributeQuery,
  OrderBy,
  PaginatedResult,
  PrismicProductWithFilteredVariantsTransform,
  PrismicVariantFilterFn,
  ProductDalI,
  ProductProjectionQuery,
  ProductProjectionsQueryArgs
} from "./ProductDalTypes"
import {
  transform,
  fullMappingStrategy,
  basicMappingStrategy,
  ColorwayAvailability
} from "./ProductReduction"
import { checkProductIsAvailableInDistributionChannel } from "./Recommendations"

export const DEFAULT_QUERY_LIMIT = 50
export const DEFAULT_PAGINATOR_MAX_RESULTS = 5000

export const allowedProductFiltersFrom = (props: GlobalProps) => {
  const { navigation } = props
  const filters = navigation?.data?.allowed_product_filters || []
  return [...filters, "utsolumarkadur"]
}

export interface DomainCategoryMapping {
  lookup(reference: CategoryReference): DomainCategory | undefined
  withSharedParent(category?: DomainCategory): DomainCategory[]
}

export class ExpandCategoryReference implements DomainCategoryMapping {
  lookup(reference: CategoryReference): DomainCategory | undefined {
    return DomainCategories.fromCategoryReference(reference) || undefined
  }

  withSharedParent(category?: DomainCategory): DomainCategory[] {
    return []
  }
}

export class AllCategories implements DomainCategoryMapping {
  private readonly categoryMap: Record<string, DomainCategory>
  private readonly categories: DomainCategory[]
  constructor(categories: DomainCategory[]) {
    this.categories = categories || []
    this.categoryMap = categories.reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue.id]: currentValue
      }),
      {}
    )
  }

  withSharedParent(category: DomainCategory | undefined) {
    return this.categories.filter(c =>
      c.ancestors.some(a => a.id === category?.parent?.id)
    )
  }

  lookup(reference: CategoryReference): DomainCategory | undefined {
    return this.categoryMap[reference.id]
  }

  categoryForKey(categoryKey: string): DomainCategory | undefined {
    return this.categories.find(c => c.key === categoryKey)
  }
}

export interface FacetedProductResults {
  products: CoreProductInformation[]
  facets: ProductFacets
}

export class ProductDal implements ProductDalI {
  private readonly root: <T>(
    project: (it: ByProjectKeyRequestBuilder) => ApiRequest<T>
  ) => Promise<ClientResponse<T>>
  private queryArgs = {
    expand: [
      "masterData.current.categories[*]",
      "masterData.current.categories[*].obj.ancestors[*]",
      "categories[*]",
      "categories[*].ancestors[*]",
      "masterVariant.attributes[*].value[*][*].value"
    ],
    limit: DEFAULT_QUERY_LIMIT
  }

  private prismicQueryArgs = {
    expand: [
      "masterData.current.categories[*]",
      "masterData.current.categories[*].obj.ancestors[*]",
      "categories[*]",
      "categories[*].ancestors[*]"
    ],
    limit: DEFAULT_QUERY_LIMIT
  }

  constructor(
    root: <T>(
      project: (it: ByProjectKeyRequestBuilder) => ApiRequest<T>
    ) => Promise<ClientResponse<T>>
  ) {
    this.root = root
  }

  public async allProductProjections<T>(
    mappingFn: (p: ProductProjection) => T
  ): Promise<T[]> {
    function variantIsAvailable(variant: ProductVariant): boolean {
      return (
        variant.attributes?.find(a => a.name === "variant-availability")?.value
          ?.key === VARIANT_IS_AVAILABLE
      )
    }

    return new Paginator(
      () => this.productProjectionsWithOffset(0, {}),
      page =>
        getNextOffsets(page).map(next =>
          this.productProjectionsWithOffset(next, {})
        ),
      DEFAULT_PAGINATOR_MAX_RESULTS
    )
      .fetchAll()
      .then(responses => responses.flatMap(resp => resp.results))
      .then(results =>
        results
          .map(p => {
            const projectionWithAvailableVariants = filterVariantsBy(
              p,
              variantIsAvailable
            )
            return projectionWithAvailableVariants
              ? mappingFn(projectionWithAvailableVariants)
              : undefined
          })
          .filter(filterTruthy)
      )
  }

  public productProjectionsWithOffset = async (
    offset = 0,
    queryArgs:
      | ProductProjectionsQueryArgs
      | null
      | Record<string, unknown> = null
  ): Promise<ProductProjectionPagedQueryResponse> => {
    const args = queryArgs || this.queryArgs
    return this.root<ProductProjectionPagedQueryResponse>(
      (it: ByProjectKeyRequestBuilder) =>
        it.productProjections().get({
          queryArgs: {
            ...args,
            offset
          }
        })
    ).then(it => it.body)
  }

  public async projectionById(
    id: string,
    store: Store,
    commerceToolsStore?: CommerceToolsStore
  ): Promise<DetailedProductInformation> {
    const mapping = fullMappingStrategy(
      store,
      null,
      new ExpandCategoryReference(),
      checkProductIsAvailableInDistributionChannel(
        commerceToolsStore?.distributionChannels
      )
    )
    return this.root<ProductProjection>(it =>
      it
        .inStoreKeyWithStoreKeyValue({
          storeKey: commerceToolsStore?.key || store.name
        })
        .productProjections()
        .withId({ ID: id })
        .get({
          queryArgs: {
            ...this.queryArgs
          }
        })
    )
      .then(it => it.body)
      .then(it => mapping.generateProduct(it))
      .catch(e => {
        logger.warn(e)
        return Promise.reject("No such product")
      })
  }

  public async projectionByKey(
    store: Store,
    staged: boolean,
    query: ProductQuery,
    category: DomainCategory | null,
    categories: DomainCategory[],
    commerceToolsStore?: CommerceToolsStore
  ): Promise<DetailedProductInformation> {
    const channelIds = await this.fetchSalesChannelsForStore(store, channel =>
      query.filterSalesChannels(channel)
    )

    const { storeProjection, localeProjection } =
      this.projectionRefinements(store)

    const fetchProduct = async () => {
      return this.root(it =>
        it
          .productProjections()
          .search() // TODO: Replace with call to get byKey in store
          .get({
            queryArgs: query.inSalesChannels(channelIds).apply({
              markMatchingVariants: true,
              staged,
              ...this.projectionRefinements(store),
              ...this.queryArgs
            })
          })
      )
        .then(it => it.body?.results)
        .then(it => query.refine(it || []))
        .then(it => {
          if (it[0] === undefined) {
            throw {
              code: 404,
              statusCode: 404,
              status: 404,
              name: "NotFound"
            }
          } else {
            return it[0]
          }
        })
        .then(it => filterMatchingVariants(it))
        .then(it => {
          if (!it) {
            throw {
              code: 404,
              statusCode: 404,
              status: 404,
              name: "NotFound"
            }
          }
          return it
        })
        .then(p =>
          transform(
            p,
            fullMappingStrategy(
              store,
              category,
              new AllCategories(categories),
              checkProductIsAvailableInDistributionChannel(
                commerceToolsStore?.distributionChannels
              ),
              query.variantAvailablity as ColorwayAvailability[]
            )
          )
        )
      // .then(
      //   it =>
      //     it &&
      //     detailsPageProductWith(_ => true).transform({
      //       p: it,
      //       props,
      //       category
      //     })
      // )
    }

    return memoizedFn(
      `${query.memo()}-${
        store.countries[0]
      }-${staged}-${storeProjection}-${localeProjection}`,
      memoTimeout.minutes(1),
      () => fetchProduct()
    ).then(it => {
      if (it) {
        return Promise.resolve(it)
      }
      return Promise.reject("No products found with code: " + query.memo())
    })
  }

  /**
   * Recursively fetch all products for a category until there are no
   * result pages left
   * @param store
   * @param query
   */
  public async allForCategory(
    store: Store,
    query: ProductQuery
  ): Promise<FacetedProductResults> {
    const cacheKey = `${store.name}-${query.memo()}`
    return memoizedFn(cacheKey, memoTimeout.minutes(1), () => {
      return this.allForQuery(store, query)
    })
  }

  public fetchStore = (store: Store): Promise<CommerceToolsStore> =>
    this.root(it =>
      it
        .stores()
        .withKey({ key: store.name })
        .get({ queryArgs: { expand: "distributionChannels[*]" } })
    ).then(it => it.body as CommerceToolsStore)

  public fetchSalesChannelsForStore = (
    store: Store,
    channelFilter: (channel?: Channel) => boolean = (channel?: Channel) =>
      !channel?.key.endsWith("store-sales")
  ): Promise<string[]> =>
    this.fetchStore(store).then(s => {
      return s.distributionChannels
        .filter(it => channelFilter(it.obj))
        .map(it => it.id)
    })

  public async search(
    query: string,
    store: Store,
    sortBy: OrderBy = OrderBy.priceAsc,
    offset = 0
  ): Promise<FacetedProductResults> {
    const key = `text.${store.language}`
    const search: { [key: string]: string } = {}
    search[key] = query
    const filterQuery = [`variants.scopedPrice.value:exists`]
    const sort = sortString(sortBy)

    const facets = new ProductFacetFactory(store.language as Language)

    const mapping = basicMappingStrategy(store, {
      productVariantsObserver: variants => facets.process(variants)
    })
    const channelFilter = await this.fetchSalesChannelsForStore(store).then(
      createSalesChannelFilter
    )

    return this.root(it =>
      it
        .productProjections()
        .search()
        .get({
          queryArgs: {
            ...search,
            filter: channelFilter,
            "filter.query": filterQuery,
            fuzzy: false,
            sort,
            markMatchingVariants: true,
            offset,
            priceCurrency: store.currency,
            priceCountry: store.country.toUpperCase(),
            ...this.projectionRefinements(store),
            ...this.queryArgs
          }
        })
    )
      .then(it => {
        return Promise.resolve(it.body)
      })
      .then(it => it.results.map(filterMatchingVariants).filter(filterTruthy))
      .then(it => it.map(mapping.generateProduct))
      .then(it => it.filter(p => Object.keys(p.colorways).length > 0))
      .then(it => ({ products: it, facets: facets.facets }))
  }

  public async query(
    query: ProductProjectionQuery
  ): Promise<ProductProjection[]> {
    if (query.isEmpty) {
      return Promise.resolve([])
    } else {
      const queryArgs = {
        where: query.where,
        ...this.prismicQueryArgs
      }
      return new Paginator(
        () => this.productProjectionsWithOffset(0, queryArgs),
        page =>
          getNextOffsets(page).map(next =>
            this.productProjectionsWithOffset(next, queryArgs)
          ),
        5 // limit to a maximum of 5 API request
      )
        .fetchAll()
        .then(results => results.flatMap(it => it.results))
        .then(prismicProductsWith(prismicOnlyVariantsWhichAreAvailable))
    }
  }

  public async allForQuery(
    store: Store,
    query: ProductQuery
  ): Promise<{ products: CoreProductInformation[]; facets: ProductFacets }> {
    const facets = new ProductFacetFactory(store.language as Language)
    const mapping = basicMappingStrategy(store, {
      productVariantsObserver: variants => facets.process(variants),
      availabilities: query.variantAvailablity as ColorwayAvailability[]
    })
    return this.allForStore(store, query)
      .then(it => it.map(filterMatchingVariants).filter(filterTruthy))
      .then(it => query.refine(it))
      .then(it => it.map(p => mapping.generateProduct(p)))
      .then(it => ({
        products: it,
        facets: facets.facets
      }))
  }

  private async allForStore(
    store: Store,
    productQuery: ProductQuery
  ): Promise<ProductProjection[]> {
    const expand = `categories[*]`
    const channelIds = await this.fetchSalesChannelsForStore(store, channel =>
      productQuery.filterSalesChannels(channel)
    )
    const queryArgs = productQuery
      .inSalesChannels(channelIds)
      .variantHasAPrice()
      .apply({
        expand,
        markMatchingVariants: true,
        ...this.projectionRefinements(store),
        sort: "id asc"
      })

    return this.paginated((project, offset) => {
      return project
        .productProjections()
        .search()
        .get({
          queryArgs: { ...queryArgs, offset }
        })
    })
  }

  private async paginated<T>(
    builder: (
      it: ByProjectKeyRequestBuilder,
      offset: number
    ) => ApiRequest<PaginatedResult & { results: T[] }>,
    maxPages?: number
  ): Promise<T[]> {
    return new Paginator(
      () => this.root(it => builder(it, 0)).then(it => it.body),
      result => {
        return getNextOffsets(result).map(async offset => {
          return this.root(it => builder(it, offset)).then(it => it.body)
        })
      },
      maxPages || DEFAULT_PAGINATOR_MAX_RESULTS
    )
      .fetchAll()
      .then(results => results.flatMap(it => it.results))
  }

  private projectionRefinements(store: Store) {
    const storeProjection = store.name
    return {
      storeProjection,
      localeProjection: store.language || Language.en
    }
  }
}

export function productVariantAvailabilityIs(
  it: ProductVariant,
  availability: string
): boolean {
  return (
    it?.attributes?.find(
      at =>
        at.name === "variant-availability" && at.value["key"] === availability
    ) !== undefined
  )
}

export enum BestFor {
  VisitingIceland = "visiting-iceland"
}
export enum SuitableFor {
  Running = "running",
  Everyday = "everyday",
  Skiing = "skiing",
  Hiking = "hiking",
  Swimming = "swimming",
  JumpingInPuddles = "jumping-in-puddles",
  PlayingInSnow = "playing-in-snow",
  OutdoorActivities = "outdoor-activities",
  SleepingInStroller = "sleeping-in-stroller"
}

function enumAttribute(attribute: string, value: string) {
  return `variants.attributes.${attribute}.key:"${value}"`
}

interface FilterQueryParams {
  fuzzy?: boolean
  fuzzyLevel?: number
  markMatchingVariants?: boolean
  staged?: boolean
  filter?: string | string[]
  "filter.facets"?: string | string[]
  "filter.query"?: string | string[]
  facet?: string | string[]
  sort?: string | string[]
  limit?: number
  offset?: number
  withTotal?: boolean
  priceCurrency?: string
  priceCountry?: string
  priceCustomerGroup?: string
  priceChannel?: string
  localeProjection?: string
  storeProjection?: string
  expand?: string | string[]
  [key: string]: QueryParam
}

function isoFormat(date: Date): string {
  return date.toISOString().split("T")[0]
}

const millisecondsInADay = 24 * 60 * 60 * 1000

const daysInThePast: (numberOfDaysAgo?: number) => Date = (
  numberOfDaysAgo = 0
) => {
  const millisecondsToSubtract = millisecondsInADay * numberOfDaysAgo
  const now = today()
  now.setTime(now.getTime() - millisecondsToSubtract)
  return now
}

interface QueryResultRefinement {
  readonly key: string
  refine(products: ProductProjection[]): ProductProjection[]
}

class MaximumResultsRefinement implements QueryResultRefinement {
  readonly key: string
  constructor(private readonly maximumResults: number) {
    this.key = `maximum-results-${maximumResults}`
  }

  refine(products: ProductProjection[]): ProductProjection[] {
    return products.slice(0, this.maximumResults)
  }
}

class ProductColorwayRefinement implements QueryResultRefinement {
  readonly key: string
  constructor(private readonly productColorways: string[]) {
    this.key = "product-colorways"
  }

  refine(products: ProductProjection[]): ProductProjection[] {
    return products
      .map(product => {
        const filteredVariants = [
          product.masterVariant,
          ...product.variants
        ].filter(v =>
          this.productColorways.find((c: string) => v.sku?.startsWith(c))
        )
        if (filteredVariants.length > 0) {
          const [masterVariant, ...variants] = filteredVariants
          return {
            ...product,
            masterVariant,
            variants
          }
        }
        return undefined
      })
      .filter(filterTruthy)
  }
}

export class ProductQuery {
  private readonly filterQueryPredicates: Set<string>
  private readonly sort: string | undefined
  private readonly isSale: boolean
  private readonly queryResultRefinements: QueryResultRefinement[]

  private constructor(
    predicates: string[],
    sort?: string,
    queryResultRefinements?: QueryResultRefinement[],
    isSale?: boolean
  ) {
    this.filterQueryPredicates = new Set(predicates)
    this.sort = sort
    this.queryResultRefinements = queryResultRefinements || []
    this.isSale = !!isSale
  }

  public filterSalesChannels(channel?: Channel): boolean {
    return channel?.key?.endsWith("store-sales") === this.isSale
  }

  get variantAvailablity(): string[] {
    const predicatePrefix = `variants.attributes.variant-availability.key:`
    return [...this.filterQueryPredicates]
      .filter(p => p.startsWith(predicatePrefix))
      .map(p => p.replace(predicatePrefix, "").replaceAll(`\"`, ""))
  }

  static forCategory(category?: DomainCategory): ProductQuery {
    return ProductQuery.forCategoryId(category?.id || "")
  }

  static forCategoryId(id: string): ProductQuery {
    return new ProductQuery([`categories.id: subtree("${id}")`])
  }

  static forProductCode(productCode: string) {
    const productKeyFilter = `key: "${productCode}"`
    return new ProductQuery([productKeyFilter])
  }

  static forProductCodes(productCodes: string[]): ProductQuery {
    return new ProductQuery([
      `key: ${productCodes.map(it => `"${it}"`).join(",")}`
    ])
  }

  public withBestFor(value: BestFor) {
    return this.with({
      predicate: enumAttribute("best-for", value)
    })
  }

  public withSuitableFor(value: SuitableFor) {
    return this.with({
      predicate: enumAttribute("tpd-suitable-for", value)
    })
  }

  withFilteredVariantAvailability(
    allowedProductFilters: string[],
    filter: string | null | undefined
  ): ProductQuery {
    const normalisedFilter =
      filter && allowedProductFilters.includes(filter) ? filter : "available"
    if (filter && filter !== normalisedFilter) {
      logger.info(`Attempted to apply invalid filter: ${filter}`)
    }

    return this.withVariantAvailability(normalisedFilter)
  }

  withVariantAvailability(availability: string) {
    return this.without("variant-availability").with({
      predicate: enumAttribute("variant-availability", availability)
    })
  }

  private without(predicate: string): ProductQuery {
    const predicates = [...this.filterQueryPredicates].filter(
      p => !p.includes(predicate)
    )
    return new ProductQuery(
      predicates,
      this.sort,
      this.queryResultRefinements,
      this.isSale
    )
  }

  private with(options: {
    predicate?: string
    sort?: string
    queryResultRefinements?: QueryResultRefinement[]
    isSale?: boolean
  }): ProductQuery {
    const {
      predicate,
      sort = this.sort,
      queryResultRefinements = this.queryResultRefinements,
      isSale = this.isSale
    } = options
    return new ProductQuery(
      predicate && predicate.length > 0
        ? [...this.filterQueryPredicates, predicate]
        : [...this.filterQueryPredicates],
      sort,
      queryResultRefinements,
      isSale
    )
  }

  withMaximum(maximumResults: number) {
    return this.with({
      queryResultRefinements: [new MaximumResultsRefinement(maximumResults)]
    })
  }

  withQueryModification(modification: (p: ProductQuery) => ProductQuery) {
    return modification(this)
  }

  memo(): string {
    return [
      ...this.filterQueryPredicates,
      ...this.queryResultRefinements.map(it => it.key),
      this.sort,
      this.isSale ? "on-sale" : ""
    ].join("-")
  }

  inSalesChannels(channelIds: string[]): ProductQuery {
    return this.with({
      predicate: createSalesChannelFilter(channelIds)
    })
  }

  apply(params: FilterQueryParams): FilterQueryParams {
    return {
      ...params,
      "filter.query": [...this.filterQueryPredicates],
      ...(this.sort && { sort: this.sort })
    }
  }

  variantHasAPrice(): ProductQuery {
    return this.with({
      predicate: "variants.prices:exists"
    })
  }

  newIn(): ProductQuery {
    return this.with({
      predicate: "variants.attributes.releaseDate:exists"
    }).with({
      predicate: `variants.attributes.releaseDate:range ("${isoFormat(daysInThePast(90))}" to "${isoFormat(today())}")`,
      sort: "variants.attributes.releaseDate desc",
      queryResultRefinements: [new MaximumResultsRefinement(60)]
    })
  }

  onSale(): ProductQuery {
    return this.with({ isSale: true })
  }

  removeCategoryFilter(): ProductQuery {
    const predicates = [...this.filterQueryPredicates].filter(
      it => !it.includes("categories.id: subtree")
    )
    return new ProductQuery(predicates, this.sort, this.queryResultRefinements)
  }

  refine(products: ProductProjection[]): ProductProjection[] {
    const refinedResults = this.queryResultRefinements.reduce(
      (reducedProducts, refinement) => {
        return refinement.refine(reducedProducts)
      },
      products
    )
    return refinedResults
  }

  withProductColorways(...productColorways: string[]): ProductQuery {
    return this.with({
      queryResultRefinements: [
        ...this.queryResultRefinements,
        new ProductColorwayRefinement(productColorways)
      ]
    })
  }
}

const createSalesChannelFilter = (channelIds: string[]): string => {
  return `variants.attributes.sales-channel.id: ${channelIds
    .map(id => `"${id}"`)
    .join(",")}`
}

export const VARIANT_IS_AVAILABLE = "available"

export const prismicOnlyVariantsWhichAreAvailable: PrismicVariantFilterFn = (
  it: ProductVariant
): boolean => productVariantAvailabilityIs(it, VARIANT_IS_AVAILABLE)

export const prismicProductWith = (
  filterFn: PrismicVariantFilterFn
): PrismicProductWithFilteredVariantsTransform => {
  return {
    transform: (p: ProductProjection): ProductProjection | undefined => {
      const [masterVariant, ...variants] = [
        p.masterVariant,
        ...p.variants
      ].filter(filterFn)
      if (!masterVariant) {
        return undefined
      }
      return {
        ...p,
        masterVariant,
        variants
      }
    }
  }
}

const prismicProductsWith =
  (filterFunction: PrismicVariantFilterFn) =>
  (products: ProductProjection[]): ProductProjection[] =>
    products
      .map(p => prismicProductWith(filterFunction).transform(p))
      .filter(p => p !== undefined) as ProductProjection[]

export function distinctBy<T, A>(arr: T[], fn: (t: T) => A): T[] {
  const results: T[] = []
  const cache: Map<A, T> = new Map()
  arr.forEach(it => {
    const key = fn(it)
    if (!cache.has(key)) {
      results.push(it)
      cache.set(key, it)
    }
  })
  return results
}

const sortString = (sort: OrderBy): string => {
  switch (sort) {
    case OrderBy.priceAsc:
      return "price asc"
    case OrderBy.priceDesc:
      return "price desc"
  }
}

export const productDal = new ProductDal(
  ServerSideCommerceToolsClient.execute.bind(ServerSideCommerceToolsClient)
)

// export const byRootCategory = (
//   product: CoreProductInformation
// ): List<ProductWithCategory> => {
//   return List(product.categories)
//     .flatMap(subCategory => {
//       return subCategory.ancestors.flatMap(category => {
//         if (category) {
//           return [
//             {
//               product,
//               category,
//               subCategory
//             }
//           ]
//         } else {
//           return []
//         }
//       })
//     })
//     .groupBy<string>(it => it.category?.id || "")
//     .map<ProductWithCategory>(it => it.first())
//     .toList()
//     .sortBy(it => it.category?.key)
// }

const generateProductProjectionQuery = (
  whereLines: string[]
): ProductProjectionQuery => {
  const isEmpty = whereLines.length === 0

  return {
    isEmpty,
    where: isEmpty ? "" : whereLines.join(" or "),
    variantAvailability: ""
  }
}

export const byProductCodes = (
  productCodes: string[]
): ProductProjectionQuery => {
  const whereLines = productCodes.map(code => `key="${code}"`)

  return generateProductProjectionQuery(whereLines)
}

export const byProductAttributes = (
  attributes: AttributeQuery[]
): ProductProjectionQuery => {
  const whereLines = attributes.flatMap(attr => [
    `variants(attributes(name="${attr.name}" and value(key="${attr.value}")))`,
    `variants(attributes(name="${attr.name}" and value(label(en="${attr.value}" or is="${attr.value}"))))`,
    `variants(attributes(name="${attr.name}" and value(en="${attr.value}" or is="${attr.value}")))`,
    `variants(attributes(name="${attr.name}" and value="${attr.value}"))`
  ])

  return generateProductProjectionQuery(whereLines)
}

const filterMatchingVariants = (
  projection: ProductProjection
): ProductProjection | undefined => {
  return filterVariantsBy(projection, variant => !!variant.isMatchingVariant)
}

const filterVariantsBy = (
  projection: ProductProjection,
  filterFn: (variant: ProductVariant) => boolean
): ProductProjection | undefined => {
  const variants = [projection.masterVariant, ...projection.variants].filter(
    filterFn
  )

  if (variants.length === 0) return undefined

  return {
    ...projection,
    masterVariant: variants[0] || projection.masterVariant,
    variants: variants.slice(1)
  }
}
