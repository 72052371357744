import { Cart, Order } from "@commercetools/platform-sdk"

export interface Store {
  name: string
  language: string
  currency: string
  country: string
  countries: string[]
}

export const Stores = {
  UK: {
    name: "uk-store",
    language: "en",
    currency: "GBP",
    countries: ["gb"],
    country: "gb"
  },
  DK: {
    name: "danish-store",
    language: "en",
    currency: "DKK",
    countries: ["dk"],
    country: "dk"
  },
  US: {
    name: "us-store",
    language: "en",
    currency: "USD",
    countries: ["us"],
    country: "us"
  },
  IS: {
    name: "iceland-store",
    language: "is",
    currency: "ISK",
    countries: ["is"],
    country: "is"
  },
  EU: {
    name: "european-store",
    language: "en",
    currency: "EUR",
    countries: ["eu"],
    country: "eu"
  }
}

export const storeEquals = (left: Store, right: Store): boolean =>
  left.name === right.name

export const storeForCart = (cart: Cart | Order): Store => {
  return Object.values(Stores)
    .map(it => it as unknown as Store)
    .find(it => it.name === cart?.store?.key)!
}
