import {
  Attribute,
  LocalizedString,
  ProductVariant,
  Image
} from "@commercetools/platform-sdk"
import { Language, Currency } from "@sixty-six-north/i18n"
import { equals, Option } from "funfix-core"
import _isArray from "lodash/isArray"
import _isObject from "lodash/isObject"
import { notUndefined } from "../prismic/PrismicIdAggregator"
import { Price } from "./models/Price"
import { SimpleAsset } from "./models/SimpleAsset"

export interface KeyAndLabel {
  key: string
  label: LocalizedString
}

export interface KeyAndValue {
  key: string
  value: string
}
export type Size = KeyAndLabel
export type Style = KeyAndLabel

export interface ModelDetailAttribute {
  name: string
  value: number | string
}

export interface Length {
  centimetres: number
  totalInches: number
  feet: number
  inches: number
}

export interface ModelDetail {
  model: "maleModel" | "femaleModel"
  height: Length | null
  chest: Length | null
  waist: Length | null
  size: string | null
}

export type GarmentComposition =
  | { name: string; value: LocalizedString }
  | { name: string; value: KeyAndLabel }
  | { name: string; value: KeyAndLabel[] }

export function findPriceForCurrencyCode(
  currency: Currency,
  prices: Price[] = []
) {
  return Option.of(prices).flatMap(p =>
    Option.of(p.find(it => it.value.currencyCode === currency))
  )
}

const MISSING_IMAGE: SimpleAsset = {
  label: "Image unavailable",
  url: "https://images.66north.com/assets/45042.png",
  tags: []
}

export const variantAssets: (variant: ProductVariant) => SimpleAsset[] =
  function (variant: ProductVariant): SimpleAsset[] {
    function uriTransform(uri: string): string {
      return uri
      // return uri.replace(
      //   "https://images.66north.com",
      //   "https://66-north.imgix.net"
      // )
    }

    const simpleAssets = (variant.assets || []).flatMap(asset =>
      asset.sources?.map(source => ({
        tags: asset.tags || [],
        url: uriTransform(source.uri),
        label: asset.name["en"] || ""
      }))
    )

    return simpleAssets.length > 0 ? simpleAssets : [MISSING_IMAGE]
  }

export type SKU =
  `${Uppercase<string>}-${Uppercase<string>}-${Uppercase<string>}`

export const skuComponents: (sku?: SKU) => {
  product: string
  color: string
  size: string
} = sku => {
  const [product, color, size] = sku?.split("-") || []
  return { product, color, size }
}

export function variantData(
  variant: ProductVariant,
  language: Language
): {
  listingDescription: string
  color: string
  size: string
  mainAsset: SimpleAsset
} {
  const attributes = variant.attributes
  const listingDescription: LocalizedString | undefined =
    attributeValue<LocalizedString>("listingDescription", attributes)

  const colorName: LocalizedString | undefined =
    attributeValue<LocalizedString>("colorName", attributes)

  const size: LocalizedString | undefined = attributeValue<Size>(
    "size",
    attributes
  )?.label

  const mainAsset: SimpleAsset = variantAssets(variant)?.[0] || MISSING_IMAGE

  return {
    listingDescription: listingDescription?.[language] || "",
    color: colorName?.[language] || "",
    size: size?.[language] || "",
    mainAsset
  }
}

export function centimetresToLength(
  v: number | string | undefined
): Length | undefined {
  const value = Number.parseInt(v as unknown as string, 10)
  if (Number.isNaN(value)) {
    return undefined
  }
  const totalInches = parseInt(
    Math.floor((value as number) * 0.393700787).toFixed(0),
    10
  )
  return {
    centimetres: value as number,
    totalInches,
    feet: Math.floor(totalInches / 12),
    inches: totalInches % 12
  }
}

export const attributeValue = <T>(
  name: string,
  attributes: Attribute[] | undefined
): T | undefined => {
  return Option.of((attributes || []).filter(it => it.name === name)[0])
    .map(it => it.value as T)
    .getOrElse(undefined)
}

const getGarmentCompositionText = (
  obj: LocalizedString | KeyAndLabel | KeyAndLabel[]
): LocalizedString[] => {
  if (_isArray(obj)) {
    return obj.flatMap(getGarmentCompositionText)
  } else if (isKeyAndLabel(obj)) {
    return [obj.label]
  } else if (isLocalizedString(obj)) {
    return [obj]
  } else {
    return []
  }
}

export const getGarmentCompositionValue = (
  name: string,
  composition: GarmentComposition[],
  language: Language
): string[] => {
  return composition
    .filter(it => it.name === name)
    .flatMap(it => asArray(it))
    .flatMap(it => getGarmentCompositionText(it.value))
    .filter(notUndefined)
    .map(it => it[language])
}

export function marshalledModelDetail(
  attributesList: ModelDetailAttribute[],
  attributeName: "maleModel" | "femaleModel"
): Option<ModelDetail> {
  if (attributesList.length === 0) {
    return Option.empty()
  }

  return Option.of({
    model: attributeName,
    height:
      centimetresToLength(
        attributesList.find(it => it.name === "modelHeightMetric")
          ?.value as number
      ) || null,
    chest:
      centimetresToLength(
        attributesList.find(it => it.name === "modelChestMeasurement")
          ?.value as number
      ) || null,
    waist:
      centimetresToLength(
        attributesList.find(it => it.name === "modelWaistMeasurement")
          ?.value as number
      ) || null,
    size:
      (attributesList.find(it => it.name === "modelWearingSize")
        ?.value as string) || null
  })
}

export const isKeyAndLabel = (
  obj: KeyAndLabel | LocalizedString
): obj is KeyAndLabel => {
  return !!obj?.key && !!obj?.label && isLocalizedString(obj.label)
}

const isLocalizedString = (
  obj: LocalizedString | string
): obj is LocalizedString => {
  return _isObject(obj) && (!!obj[Language.en] || !!obj[Language.is])
}

export const asArray = <T>(value: T | T[]): T[] => {
  if (_isArray(value)) return value
  else return [value]
}

export const nonEmpty = (value: string) => value.length > 0
