import { createSlice } from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"
import { useRootSelector } from "../State"

export interface CheckoutStateI {
  showReviewPageOverview: boolean
}

const initialCheckoutStateI: CheckoutStateI = {
  showReviewPageOverview: true
}

const CheckoutState = createSlice({
  name: "checkoutState",
  initialState: initialCheckoutStateI,
  reducers: {
    hideOverview: state => ({ ...state, showReviewPageOverview: false }),
    clear: () => initialCheckoutStateI
  }
})

export const useCheckoutState = () => {
  const state = useRootSelector(it => it.checkoutState)
  const dispatch = useDispatch()

  return {
    state,
    hideOverview: () => dispatch(CheckoutState.actions.hideOverview()),
    clear: () => dispatch(CheckoutState.actions.clear())
  }
}

export default CheckoutState.reducer
