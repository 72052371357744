//jsxhook
import { Country } from "@sixty-six-north/i18n"
import { Language } from "@sixty-six-north/i18n"
import { LocalizedString, Order } from "@commercetools/platform-sdk"
import { TAG_FILTER_CONFIG } from "filters/TagFilterHooks"
import { UrlObject } from "url"
import { useCartId } from "../cart/CartServiceHook"
import { useVariantFilter } from "../category/CategoryHooks"
import { countryToPath } from "../i18n/Country"
import { useLocaleAwareLinks } from "../i18n/StoreHooks"
import { DomainCategory } from "../product/models/DomainCategory"
import { OrderBy } from "../product/ProductDalTypes"

const passHref = { passHref: true }

export interface NextLink {
  as: string
  href: UrlObject
  passHref: boolean
}

export class ProductUri {
  private readonly slug: LocalizedString
  private readonly productCode: string
  constructor(slug: LocalizedString, productCode: string = "") {
    this.slug = slug
    this.productCode = productCode
  }

  public path(language: Language): string {
    return `${this.slug[language] || "_"}/p/${this.productCode}`
  }
}

export class LinkBuilder {
  private readonly country: Country
  private readonly regionPath: string
  private readonly language: Language

  constructor(country: Country) {
    this.country = country
    this.language = Country.is === country ? Language.is : Language.en
    this.regionPath = countryToPath(country)
  }

  public productProjectionLink(
    categorySlug: string | undefined,
    subCategorySlug: string | undefined,
    productUri: ProductUri,
    color?: string | undefined,
    variantFilter?: string,
    prev: string | undefined | null = "impressionView"
  ): NextLink {
    const params: string[] = []
    const queries: Record<string, string> = {}
    let catOrSub = categorySlug
    // let search go to the sub cat
    if (subCategorySlug) catOrSub = subCategorySlug

    if (catOrSub) params.push(`c=${catOrSub}`)
    if (catOrSub) queries["c"] = catOrSub

    if (color) params.push(`color=${color}`)
    if (color) queries["color"] = color

    if (prev) params.push(`prev=${prev}`)
    if (prev) queries["prev"] = prev

    if (variantFilter && variantFilter !== "available") {
      params.push(`vf=${variantFilter}`)
      queries["vf"] = variantFilter
    }

    const pathname = `/${this.regionPath}/${productUri.path(this.language)}`
    const queryString = params.length > 0 ? `?${params.join("&")}` : ""
    const as = `${pathname}${queryString}`
    const href = { pathname, query: queries }

    return { as, href, ...passHref }
  }

  public categoryLink(
    category: DomainCategory,
    variantFilter?: string
  ): NextLink {
    const slug = category.slug[this.language]
    const pathParameters = [this.regionPath, slug]
    const as = `/${pathParameters.join("/")}`
    const query = variantFilter && { vf: variantFilter }
    const href = { pathname: as, query }
    return { href, as, ...passHref }
  }

  public tagLink(tag: string, categorySlug: string): NextLink {
    const tagFilter = TAG_FILTER_CONFIG[tag]?.slug[this.language] || ""
    const pathParameters = [this.regionPath, `${categorySlug}-${tagFilter}`]

    const as = `/${pathParameters.join("/")}`
    const href = { pathname: as }
    return { href, as, ...passHref }
  }

  public shelfLink(shelf: string, category: DomainCategory): NextLink {
    const slug = category.slug[this.language]
    const pathParameters = [this.regionPath, slug]
    const as = `/${pathParameters.join("/")}`
    const href = { pathname: as, query: { s: shelf } }
    return { href, as, ...passHref }
  }

  public genericLink(link: string): NextLink {
    const [path, queryString] = link.split("?")
    const query = {}
    if (queryString) {
      const queryParams = new URLSearchParams(queryString)
      for (const [key, value] of queryParams) {
        // each 'entry' is a [key, value] tupple
        query[key] = value
      }
    }

    const pathParameters = [path.replace(/^\//, "")]
    const as = `/${pathParameters.join("/")}`
    const href = { pathname: as, query }
    return { href, as, ...passHref }
  }

  public localisedLink(slug: LocalizedString): NextLink {
    const path = slug[this.language]
    const pathParameters = [this.regionPath, path]
    const as = `/${pathParameters.join("/")}`
    const href = { pathname: as }
    return { href, as, ...passHref }
  }
}

export const useHomeLink = () => {
  const href = "/"
  const as = "/"
  return { href, as, ...passHref }
}

export const useContactLink = () => {
  const href = "/contact-us"
  const as = "/contact-us"
  return { as, href, ...passHref }
}

export const useCartLink = () => {
  const href = "/checkout"
  const as = "/checkout"
  return { as, href, ...passHref }
}

export const useAddPromoCodeLink = () => {
  const href = "/checkout/add-promo-code"
  const as = "/checkout/add-promo-code"
  return { href, as, ...passHref }
}

export const useCustomerInformationLink = () => {
  const href = "/checkout/information"
  const as = "/checkout/information"
  return { href, as, ...passHref }
}

export const usePaymentLink = () => {
  const href = "/checkout/payment"
  const as = "/checkout/payment"
  return { href, as, ...passHref }
}

export const useReviewLink = () => {
  const href = "/checkout/review"
  const as = "/checkout/review"
  return { href, as, ...passHref }
}

export const createProcessingLink = (cartId: string) => {
  return {
    href: `/checkout/${cartId}/processing`,
    as: `/checkout/${cartId}/processing`
  }
}

export const createProcessingFailureLink = () => {
  return {
    href: "/checkout/failure",
    as: `/checkout/failure`
  }
}

export const useProcessingLink = () => {
  const cartId = useCartId()
  return { ...createProcessingLink(cartId || ""), ...passHref }
}

export const useOrderCompleteLink = () => {
  const href = "/checkout/complete"
  const as = "/checkout/complete"
  return { href, as, ...passHref }
}

export const useCategoryLink = (category: DomainCategory) => {
  const variantFilter = useVariantFilter()
  const linkBuilder = useLocaleAwareLinks()

  return linkBuilder.categoryLink(category, variantFilter)
}

export const useCategoryLinkBuilder = () => {
  const variantFilter = useVariantFilter()
  const linkBuilder = useLocaleAwareLinks()

  return {
    categoryLink: (category: DomainCategory) =>
      linkBuilder.categoryLink(category, variantFilter)
  }
}

export const useSubCategoryLink = (
  category: DomainCategory,
  subCategory: DomainCategory
) => {
  return useCategoryLink(subCategory)
}

export const createSearchLink = (
  query: string,
  country: Country,
  sortBy: OrderBy
) => {
  const href = {
    pathname: `/${countryToPath(country)}/search`,
    query: { query, sortBy }
  }
  const as = {
    pathname: `/${countryToPath(country)}/search`,
    query: { query, sortBy }
  }
  return { href, as }
}

export const useProductProjectionLink = (
  categorySlug: string,
  subCategorySlug: string,
  productUri: ProductUri,
  defaultVariant?: string,
  tag?: string | null
) => {
  const variantFilter = useVariantFilter()
  const linkBuilder = useLocaleAwareLinks()

  return linkBuilder.productProjectionLink(
    categorySlug,
    tag ? `${categorySlug}-${tag}` : subCategorySlug,
    productUri,
    defaultVariant || "",
    variantFilter,
    "impressionView"
  )
}

export const useDashboardLink = () => {
  const href = "/dashboard"
  const as = "/dashboard"
  return { href, as, ...passHref }
}

export const useUpdatePasswordLink = () => {
  const href = "/dashboard/update-password"
  const as = "/dashboard/update-password"
  return { href, as, ...passHref }
}

export const createUpdateShippingAddressLink = (
  addressId: string | undefined
) => {
  const href = `/dashboard/update-shipping-address/${addressId}`
  const as = `/dashboard/update-shipping-address/${addressId}`
  return { href, as, ...passHref }
}

export const useLoginLink = () => {
  const href = "/login"
  const as = "/login"
  return { href, as, ...passHref }
}

export const useRegistrationLink = () => {
  const href = "/register"
  const as = "/register"
  return { href, as, ...passHref }
}

export const usePasswordResetLink = () => {
  const href = "/account/reset"
  const as = "/account/reset"
  return { href, as, ...passHref }
}

export const usePasswordResetThankYouLink = () => {
  const href = "/account/thank-you"
  const as = "/account/thank-you"
  return { href, as, ...passHref }
}

export const usePasswordUpdatedLink = () => {
  const href = "/account/password-updated"
  const as = "/account/password-updated"
  return { href, as, ...passHref }
}

export const orderConfirmationUrl = (order: Order, reportPurchase = false) => {
  const href = `/checkout/confirmation/${order.orderNumber}`
  const as = `/checkout/confirmation/${order.orderNumber}`
  return { href, as, ...passHref }
}

export const orderErrorUrl = () => {
  const href = `/checkout/error`
  const as = `/checkout/error`
  return { href, as, ...passHref }
}

export const orderThanksUrl = () => {
  const href = "/checkout/thank-you"
  const as = "/checkout/thank-you"
  return { href, as, ...passHref }
}
