import {
  ProductVariant,
  AttributeLocalizedEnumValue
} from "@commercetools/platform-sdk"
import { Language } from "@sixty-six-north/i18n"
import { FilterValue } from "../filters/FilterComponent"
import { SubColors } from "../filters/FilterHooks"
import { bySizeKeyOrder } from "../product/ProductHooks"
import { toAttributeMap } from "../product/ProductReduction"

export interface ProductFacets {
  colors: FilterValue[]
  subColors: SubColors
  styles: FilterValue[]
  sizes: FilterValue[]
  functionalities: FilterValue[]
}

export class ProductFacetFactory {
  private readonly colors: Record<string, FilterValue> = {}
  private readonly subColors: SubColors = {}
  private readonly styles: Record<string, FilterValue> = {}
  private readonly sizes: Record<string, FilterValue> = {}
  private readonly functionalities: Record<string, FilterValue> = {}
  private readonly language: Language

  constructor(language: Language) {
    this.language = language
  }

  private addColor(attributes: Record<string, unknown>) {
    const { colorTerm, colorHexcode } = attributes

    const { key: filterValue, label } = colorTerm as AttributeLocalizedEnumValue

    this.colors[filterValue] = {
      filterLabel: label[this.language],
      filterValue,
      isActive: false
    }
    const hex = colorHexcode as string
    if (hex) {
      if (this.subColors[filterValue]) {
        if (!this.subColors[filterValue].includes(hex)) {
          this.subColors[filterValue].push(hex)
        }
      } else {
        this.subColors[filterValue] = [hex]
      }
    }
  }

  private addSize(attributes: Record<string, unknown>) {
    const { size } = attributes
    const { key, label } = size as AttributeLocalizedEnumValue

    this.sizes[key] = {
      filterLabel: label[this.language],
      filterValue: key,
      isActive: false
    }
  }

  private addStyle(attributes: Record<string, unknown>) {
    const { style } = attributes
    if (!style) return
    const { key, label } = style as AttributeLocalizedEnumValue

    this.styles[key] = {
      filterLabel: label[this.language],
      filterValue: key,
      isActive: false
    }
  }

  private addFunctionality(attributes: Record<string, unknown>) {
    const { functionality } = attributes
    if (!functionality) return
    const functionalities = functionality as AttributeLocalizedEnumValue[]

    functionalities.forEach(({ key, label }) => {
      this.functionalities[key] = {
        filterLabel: label[this.language],
        filterValue: key,
        isActive: false
      }
    })
  }

  public process(variants: ProductVariant[]) {
    variants.forEach((v, index) => {
      const attributeMap = toAttributeMap(v)
      this.addColor(attributeMap)
      this.addSize(attributeMap)
      if (index === 0) {
        this.addStyle(attributeMap)
        this.addFunctionality(attributeMap)
      }
    })

    return this
  }

  public get facets(): ProductFacets {
    const sizes = Object.values(this.sizes).sort((left, right) =>
      bySizeKeyOrder({ size: left.filterValue }, { size: right.filterValue })
    )
    return {
      colors: Object.values(this.colors),
      subColors: this.subColors,
      styles: Object.values(this.styles),
      sizes,
      functionalities: Object.values(this.functionalities)
    }
  }
}
