//jsxhook

import { useUrlSearch } from "./UrlSearchHooks"
import { LocalizedString } from "@commercetools/platform-sdk"
import { BestFor, ProductQuery, SuitableFor } from "../product/ProductDal"

interface Tag {
  name: LocalizedString
  slug: LocalizedString
  filterModification: (pq: ProductQuery) => ProductQuery
}

const everyday: Tag = {
  name: {
    en: "Everyday",
    is: "Hversdags"
  },
  slug: {
    en: "everyday",
    is: "hversdags"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.Everyday)
}
const running: Tag = {
  name: {
    en: "Running",
    is: "Hlaup"
  },
  slug: {
    en: "running",
    is: "hlaup"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.Running)
}

const skiing: Tag = {
  name: {
    en: "Skiing",
    is: "Skíði"
  },
  slug: {
    en: "skiing",
    is: "skidi"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.Skiing)
}
const hiking: Tag = {
  name: {
    en: "Hiking",
    is: "Göngur"
  },
  slug: {
    en: "hiking",
    is: "gongur"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.Hiking)
}
const swimming: Tag = {
  name: {
    en: "Swimming",
    is: "Sund"
  },
  slug: {
    en: "swimming",
    is: "sund"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.Swimming)
}
const visitingIceland: Tag = {
  name: {
    en: "Visiting Iceland",
    is: "Heimsækja Ísland"
  },
  slug: {
    en: "visiting-iceland",
    is: "heimsaekja-island"
  },
  filterModification: it => it.withBestFor(BestFor.VisitingIceland)
}
const jumpingInPuddles: Tag = {
  name: {
    en: "Jumping in puddles",
    is: "Hoppa í polla"
  },
  slug: {
    en: "jumping-in-puddles",
    is: "hoppa-i-polla"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.JumpingInPuddles)
}
const playingInTheSnow: Tag = {
  name: {
    en: "Playing in the snow",
    is: "Sleðafjör"
  },
  slug: {
    en: "playing-in-snow",
    is: "sledafjor"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.PlayingInSnow)
}
const outdoorActivities: Tag = {
  name: {
    en: "Outdoor Activities",
    is: "Út að leika"
  },
  slug: {
    en: "outdoor-activities",
    is: "ut-ad-leika"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.OutdoorActivities)
}
const sleepingInTheStroller: Tag = {
  name: {
    en: "Sleeping in the stroller",
    is: "Í værum blundi í vagninum"
  },
  slug: {
    en: "sleeping-in-stroller",
    is: "blundar-i-vagninum"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.SleepingInStroller)
}
const newArrivals: Tag = {
  name: {
    en: "New Arrivals",
    is: "Nýjar vörur"
  },
  slug: {
    en: "new-arrivals",
    is: "nyjar-vorur"
  },
  filterModification: it => it.newIn()
}

const exclusiveDiscount: Tag = {
  name: {
    en: "Exclusive discount",
    is: "Lagersala"
  },
  slug: {
    en: "exclusive-discount",
    is: "lagersala"
  },
  filterModification: it =>
    it.withProductColorways(...festivalEssentialColorways)
}

const outlet: Tag = {
  name: {
    en: "Sale",
    is: "Útsölumarkaður"
  },
  slug: {
    en: "sale",
    is: "utsolumarkadur"
  },
  filterModification: it => it.onSale()
}

const allProducts: Tag = {
  name: {
    en: "All Products",
    is: "Allar vörur"
  },
  slug: {
    en: "all-products",
    is: "allar-vorur"
  },
  filterModification: it => it.removeCategoryFilter()
}

const tags: Tag[] = [
  everyday,
  running,
  skiing,
  hiking,
  swimming,
  visitingIceland,
  jumpingInPuddles,
  playingInTheSnow,
  outdoorActivities,
  sleepingInTheStroller,
  newArrivals,
  exclusiveDiscount,
  // privateSale,
  outlet,
  allProducts
]

export const TAG_FILTER_CONFIG: Record<string, Tag> = tags.reduce(
  (previousValue, currentValue) => {
    return {
      ...previousValue,
      [currentValue.slug.en]: currentValue,
      [currentValue.slug.is]: currentValue
    }
  },
  {}
)

export function onDynamicCategory<T>(
  categorySlug: string | null,
  callback: (dynamicCategory: string, tag: Tag) => T
) {
  const dynamicCategorySlugs = Object.keys(TAG_FILTER_CONFIG)
  const dynamicCategory = dynamicCategorySlugs.find(slug =>
    categorySlug?.endsWith(slug)
  )
  if (!dynamicCategory) return undefined
  return callback(dynamicCategory, TAG_FILTER_CONFIG[dynamicCategory])
}

export const getCommerceToolsFilterForSlug = (
  slug?: string
): ((plq: ProductQuery) => ProductQuery) => {
  const noModification: (plq: ProductQuery) => ProductQuery = q => q
  return (slug && TAG_FILTER_CONFIG[slug]?.filterModification) || noModification
}
export const useTagFilter = () => useUrlSearch("tag")

const festivalEssentialColorways: string[] = [
  "K41154-132",
  "K41141-132",
  "W41243-192",
  "W11322-192",
  "W11320-192",
  "W11258-192",
  "W11301-197",
  "W11302-197",
  "Q01246-224",
  "V66113-224",
  "Q01245-224",
  "L81228-245",
  "U99164-296",
  "L81218-296",
  "L81175-296",
  "W41259-299",
  "Q51140-299",
  "W11259-299",
  "L81288-308",
  "W11381-308",
  "W11380-308",
  "U99211-380",
  "W11379-380",
  "U99164-380",
  "L81232-380",
  "V66109-380",
  "Q01170-402",
  "L81157-402",
  "Q01206-402",
  "L81468-463",
  "W11257-491",
  "W11314-499",
  "L81216-531",
  "W11324-551",
  "W41165-551",
  "W11325-551",
  "W11269-551",
  "W11257-574",
  "L81217-574",
  "K41154-791",
  "W11395-795",
  "W11396-795",
  "Q01170-795",
  "V66099-804",
  "W11381-842",
  "W11380-842",
  "V66099-842",
  "V66109-842",
  "W61282-854",
  "W41243-900",
  "W11395-900",
  "W11320-900",
  "K01247-900",
  "K61245-900",
  "W11257-900",
  "W41261-900",
  "W61247-900",
  "W61248-900",
  "W11258-900",
  "W41260-900",
  "W11293-909",
  "W61286-909",
  "W61280-909",
  "W11803-909",
  "W11294-909",
  "W61281-909",
  "K41154-981",
  "K61245-C192",
  "K01247-C192",
  "K41150-N441",
  "K11169-N441",
  "K11167-N441",
  "W11174-N900"
]
