import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PrismicDocument } from "../prismic/PrismicModels"
import { useRootSelector, useAppDispatch } from "../State"

export interface LayoutStateI {
  menuOpen: boolean
  previousScroll: number
  secondMenuOpen: boolean
  searchOpen: boolean
  transparentMenu: boolean
  mobileProductsMultiColumnGrid: boolean
  tray?: PrismicDocument
}

const DefaultLayoutState: LayoutStateI = {
  menuOpen: false,
  secondMenuOpen: false,
  previousScroll: 0,
  searchOpen: false,
  transparentMenu: false,
  mobileProductsMultiColumnGrid: true
}

const LayoutState = createSlice({
  name: "LayoutState",
  initialState: DefaultLayoutState,
  reducers: {
    openTray: (state: LayoutStateI) => ({
      ...state,
      menuOpen: true,
      previousScroll: !state.menuOpen ? window.scrollY : state.previousScroll
    }),
    closeTray: (state: LayoutStateI) => ({
      ...state,
      menuOpen: false
    }),
    openSecondTray: (state: LayoutStateI) => ({
      ...state,
      menuOpen: true,
      secondMenuOpen: true
    }),
    closeSecondTray: (state: LayoutStateI) => ({
      ...state,
      secondMenuOpen: false
    }),

    closeAllMenus: (state: LayoutStateI) => ({
      ...state,
      menuOpen: false,
      secondMenuOpen: false
    }),
    toggleSearch: (state: LayoutStateI) => ({
      ...state,
      menuOpen: false,
      previousScroll: 0,
      searchOpen: !state.searchOpen
    }),
    toggleMobileProductsMultiColumnGrid: (state: LayoutStateI) => ({
      ...state,
      mobileProductsMultiColumnGrid: !state.mobileProductsMultiColumnGrid
    }),

    setMenuTransparency: (
      state: LayoutStateI,
      action: PayloadAction<boolean>
    ): LayoutStateI => ({
      ...state,
      transparentMenu: action.payload
    })
  }
})

export const useLayout = () => {
  const state: LayoutStateI = useRootSelector(it => it.layoutState)
  const dispatch = useAppDispatch()

  return {
    state,
    closeAllMenus: () => dispatch(LayoutState.actions.closeAllMenus()),
    openTray: () => dispatch(LayoutState.actions.openTray()),
    closeTray: () => dispatch(LayoutState.actions.closeTray()),
    openSecondTray: () => dispatch(LayoutState.actions.openSecondTray()),
    closeSecondTray: () => dispatch(LayoutState.actions.closeSecondTray()),
    disableMenuTransparency: () =>
      dispatch(LayoutState.actions.setMenuTransparency(false)),
    toggleMobileProductsMultiColumnGrid: () =>
      dispatch(LayoutState.actions.toggleMobileProductsMultiColumnGrid()),
    enableMenuTransparency: () =>
      dispatch(LayoutState.actions.setMenuTransparency(true)),
    toggleSearch: () => dispatch(LayoutState.actions.toggleSearch())
  }
}

export default LayoutState.reducer
