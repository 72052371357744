//jsxhook
import { LocalizedString } from "@commercetools/platform-sdk"
import { useContext } from "react"
import { CoreProductInformation } from "../product/models/DetailedProductInformation"
import { DomainCategory } from "../product/models/DomainCategory"
import { filterTruthy } from "../utils/Filter"
import {
  CurrentCategoryContext,
  RootCategoriesContext,
  SubCategoryContext,
  VariantFilterContext,
  AllCategoriesMapContext
} from "./CategoriesContexts"

type StyleKey = string

export interface GroupedProducts {
  styleKey: string
  styleName: string
  products: CoreProductInformation[]
}

export const groupProductsIntoShelves: (
  products: CoreProductInformation[]
) => GroupedProducts[] = (products: CoreProductInformation[]) => {
  const shelves = products.reduce(
    (previousValue, currentValue) => {
      const styleKey = currentValue.facets.style
      const groupedProducts = previousValue[styleKey] || {
        styleKey,
        styleName: styleKey,
        products: []
      }

      return {
        ...previousValue,
        [styleKey]: {
          ...groupedProducts,
          products: [...groupedProducts.products, currentValue]
        }
      }
    },
    {} as Record<StyleKey, GroupedProducts>
  )

  return Object.values(shelves).sort((a, b) =>
    a.styleKey.localeCompare(b.styleKey)
  )
}

export const useAllCategories: () => DomainCategory[] = () =>
  Object.values(useContext(AllCategoriesMapContext))

const byOrderHint = (left: DomainCategory, right: DomainCategory) =>
  +right.orderHint - +left.orderHint

const getRootCategories = (categories: DomainCategory[]): DomainCategory[] => {
  return categories.filter(it => it.ancestors.length === 0).sort(byOrderHint)
}
export const useTopLevelCategories: () => DomainCategory[] = () =>
  getRootCategories(useAllCategories())

interface CategoryLookup {
  categoryLookup: (
    categoryKey: string | undefined
  ) => DomainCategory | undefined
  firstSubCategoryUnderTopLevel: (
    product: CoreProductInformation,
    topLevelCategory: string
  ) => DomainCategory | undefined
  topLevelCategoryFor: (categoryKeys: string[]) => DomainCategory | undefined
  topLevelCategoriesFor: (categoryKeys: string[]) => DomainCategory[]
  defaultCategoryFor: (product: CoreProductInformation) => DomainCategory
  forSlug: (slug: string) => DomainCategory | undefined
}

export const useAllCategoriesLookup: () => CategoryLookup = () => {
  const categoryMap = useContext(AllCategoriesMapContext)

  const categoryLookup: (
    categoryKey: string | undefined
  ) => DomainCategory | undefined = (
    categoryKey: string | undefined
  ): DomainCategory | undefined => {
    return categoryKey ? categoryMap?.[categoryKey] : undefined
  }
  const topLevelCategoriesFor = (categoryKeys: string[]): DomainCategory[] => {
    const topLevelCategoryKeys: Record<string, DomainCategory> = categoryKeys
      .map(key => categoryLookup(key))
      .filter(filterTruthy)
      .flatMap((it: DomainCategory) =>
        it.ancestors?.length === 0 ? [it] : it.ancestors
      )
      .reduce(
        (previousValue, currentValue) => {
          return { ...previousValue, [currentValue.key]: currentValue }
        },
        {} as Record<string, DomainCategory>
      )
    return Object.values(topLevelCategoryKeys)
  }
  return {
    categoryLookup,
    firstSubCategoryUnderTopLevel: (
      product: CoreProductInformation,
      topLevelCategory: string
    ): DomainCategory | undefined => {
      const matchingSubCategories: DomainCategory[] = product.categoryKeys
        .filter(categoryKey => categoryKey.startsWith(topLevelCategory))
        .map((categoryKey: string) => categoryLookup(categoryKey))
        .filter(filterTruthy)
        .map(it => it as DomainCategory)

      return [...matchingSubCategories, categoryLookup(topLevelCategory)][0]
    },
    topLevelCategoryFor: (
      categoryKeys: string[]
    ): DomainCategory | undefined => {
      const topLevelCategories = topLevelCategoriesFor(categoryKeys)
      return topLevelCategories.length > 0 ? topLevelCategories[0] : undefined
    },
    topLevelCategoriesFor,
    defaultCategoryFor: (product: CoreProductInformation): DomainCategory => {
      const categories = product.categoryKeys
        .map(key => categoryLookup(key))
        .filter(filterTruthy)
        .map(it => it as DomainCategory)

      return (categories.length > 0 && categories[0]) || categoryLookup("men")!
    },
    forSlug: (slug: string): DomainCategory | undefined =>
      Object.values(categoryMap).find((it: DomainCategory) =>
        Object.values(it?.slug || {}).includes(slug)
      ) as DomainCategory | undefined
  }
}

export const useRootCategories = () => useContext(RootCategoriesContext)
export const useCurrentCategory = () => useContext(CurrentCategoryContext)
export const useSubCategory = () => useContext(SubCategoryContext)
export const useVariantFilter = () => useContext(VariantFilterContext)
