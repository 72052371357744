import {
  ApiRequest,
  ClientResponse,
  Customer,
  Cart,
  ByProjectKeyRequestBuilder
} from "@commercetools/platform-sdk"
import { TokenInfo } from "@commercetools/sdk-client-v2"
import { EnhancedStore } from "@reduxjs/toolkit"
import { useConfig } from "../Config"
import React, { useEffect, ReactNode } from "react"
import { useAppDispatch, useAppStore } from "../State"
import {
  CommerceToolsClient,
  SimpleCommerceToolsClient
} from "./CommerceToolsClient"
import { withAuth } from "./withAuth"

const currentAccessTokenFrom = (store: EnhancedStore) => {
  return () => store.getState().accessTokenState?.token
}

class NoOpCommerceToolsClient implements CommerceToolsClient {
  public execute<T>(
    fn: (project: ByProjectKeyRequestBuilder) => ApiRequest<T>
  ): Promise<ClientResponse<T>> {
    return Promise.reject(new Error("Not implemented"))
  }

  public signIn(
    email: string,
    password: string,
    recaptcha: Promise<string | undefined>,
    cartId: string | undefined
  ): Promise<{ token: TokenInfo; customer: Customer; cart?: Cart }> {
    return Promise.reject(new Error("Not implemented"))
  }

  public logout(): Promise<void> {
    return Promise.reject(new Error("Not implemented"))
  }
}

export const UserClientContext = React.createContext<CommerceToolsClient>(
  new NoOpCommerceToolsClient()
)

export const CommerceToolsClientProvider: React.FC<{
  children?: ReactNode
}> = ({ children }) => {
  const store = useAppStore()
  const dispatch = useAppDispatch()
  const config = useConfig()

  const client = new SimpleCommerceToolsClient(
    currentAccessTokenFrom(store),
    dispatch,
    config.commerceTools
  )

  useEffect(() => {
    withAuth(
      () => store.getState().accessTokenState?.token,
      dispatch
    )(() => Promise.resolve(true))
  }, [])

  return (
    <UserClientContext.Provider value={client}>
      {children}
    </UserClientContext.Provider>
  )
}
